




























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  GetECMRSByShipment_GetECMRSByShipment,
  GetECMRSByShipment_GetECMRSByShipment_deliveryPickedUpEmballage
} from '@/generated/GetECMRSByShipment'

@Component({
  components: {}
})
export default class ECMRView extends Vue {
  @Prop({ default: () => [] }) ecmr: GetECMRSByShipment_GetECMRSByShipment

  join(strings: string[]) {
    return strings
  }

  created() {
    this.$store.state.mobileTitle = this.ecmr.identifier
  }

  destroyed() {
    this.$store.state.mobileTitle = undefined
  }

  getAllEmballage(): Map<string, number> {
    return new Map(
        [
            ...new Map((this.ecmr.deliveryPickedUpEmballage?.items?.map(object =>[object.key, object.value])) ?? [])
        ]
    )
  }

  containsEmballage(emballage: GetECMRSByShipment_GetECMRSByShipment_deliveryPickedUpEmballage | null) {
    return emballage && emballage.items.length
  }

  getEmballageValueOrEmpty(emballage: GetECMRSByShipment_GetECMRSByShipment_deliveryPickedUpEmballage | undefined, searchedKey: string): string {
    return emballage?.items?.find(item => item.key === searchedKey)?.value?.toString() ?? ""
  }

}
