




import {Component, Mixins} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import ECMRService from "@/modules/ecmrs/ECMRService";
import { GetECMR_GetECMR } from '@/generated/GetECMR';
import ECMRView from '@/modules/shipments/views/detail/components/ECMRView.vue';
import { GetECMRSByPlanning_GetECMRSByPlanning_items_value } from '@/generated/GetECMRSByPlanning';

@Component({
  components: {
    BaseDataTable,
    InfoCard,
    ECMRView
  }
})
export default class Overview extends Mixins(ECMRService) {
  ecmr: GetECMR_GetECMR | null = null

  created() {
    if (this.$route.query.cache && Boolean(this.$route.query.cache)) {
      const shipmentEcmrs: Array<GetECMRSByPlanning_GetECMRSByPlanning_items_value[]> = Array.from(this.$store.state.transport.ecmrs.values())
      this.ecmr = shipmentEcmrs
        .flatMap((x: GetECMRSByPlanning_GetECMRSByPlanning_items_value[]) => x)
        .find((ecmr: GetECMRSByPlanning_GetECMRSByPlanning_items_value) => ecmr.listData.id === Number(this.$route.params.id)) || null
    } else {
      this.getECMR(Number(this.$route.params.id)).then(ecmr => {
        this.ecmr = ecmr
      })
    }
  }
}
